import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'DSP',
  description:
    'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in DSP Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by DSP), NFTs, and more, on a platform you can trust.',
  image: 'https://donutswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `DonutSwap | ${t('Home')}`,
      }
    case '/swap':
      return {
        title: `DonutSwap | ${t('Exchange')}`,
      }
    case '/add':
      return {
        title: `DonutSwap | ${t('Add Liquidity')}`,
      }
    case '/remove':
      return {
        title: `DonutSwap | ${t('Remove Liquidity')}`,
      }
    case '/liquidity':
      return {
        title: `DonutSwap | ${t('Liquidity')}`,
      }
    case '/find':
      return {
        title: `DonutSwap | ${t('Import Pool')}`,
      }
    case '/competition':
      return {
        title: `DonutSwap | ${t('Trading Battle')}`,
      }
    case '/prediction':
      return {
        title: `DonutSwap | ${t('Prediction')}`,
      }
    case '/prediction/leaderboard':
      return {
        title: `DonutSwap | ${t('Leaderboard')}`,
      }
    case '/farms':
      return {
        title: `DonutSwap | ${t('Farms')}`,
      }
    case '/farms/auction':
      return {
        title: `DonutSwap | ${t('Farm Auctions')}`,
      }
    case '/pools':
      return {
        title: `DonutSwap | ${t('Pools')}`,
      }
    case '/lottery':
      return {
        title: `DonutSwap | ${t('Lottery')}`,
      }
    case '/ifo':
      return {
        title: `DonutSwap | ${t('Initial Farm Offering')}`,
      }
    case '/teams':
      return {
        title: `DonutSwap | ${t('Leaderboard')}`,
      }
    case '/voting':
      return {
        title: `DonutSwap | ${t('Voting')}`,
      }
    case '/voting/proposal':
      return {
        title: `DonutSwap | ${t('Proposals')}`,
      }
    case '/voting/proposal/create':
      return {
        title: `DonutSwap | ${t('Make a Proposal')}`,
      }
    case '/info':
      return {
        title: `DonutSwap | ${t('Overview')} | ${t('DonutSwap Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/pools':
      return {
        title: `DonutSwap | ${t('Pools')} | ${t('DonutSwap Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/info/tokens':
      return {
        title: `DonutSwap | ${t('Tokens')} | ${t('DonutSwap Info & Analytics')}`,
        description: 'View statistics for Pancakeswap exchanges.',
      }
    case '/nfts':
      return {
        title: `DonutSwap | ${t('Overview')}`,
      }
    case '/nfts/collections':
      return {
        title: `${t('Collections')}`,
      }
    case '/nfts/profile':
      return {
        title: `${t('Your Profile')}`,
      }
    case '/pancake-squad':
      return {
        title: `${t('Pancake Squad')}`,
      }
    default:
      return null
  }
}
